import { Controller } from "@hotwired/stimulus"
import { Sortable } from "sortablejs"

export default class extends Controller {
  static targets = ["box", "pile", "name"]

  initialize() {
    this.name = "headers"
    this.maxSize = 3
  }

  connect() {
    if (this.hasBoxTarget) Sortable.create(this.boxTarget, this.sortableOptions)
    if (this.hasPileTarget) Sortable.create(this.pileTarget, this.sortableOptions)
  }

  update() {
    this.boxTarget.querySelectorAll("input").forEach(el => {
      el.disabled = false
    })

    this.pileTarget.querySelectorAll("input").forEach(el => {
      el.disabled = true
    })
  }

  get sortableOptions() {
    return {
      group: {
        name: this.name,
        put: (to, _from) => {
          if (to.el == this.boxTarget && to.el.children.length == this.maxSize) {
            return false
          } else {
            return [this.name]
          }
        }
      },
      animation: 200,
      onEnd: () => this.update(),
    }
  }

  submit() {
    this.dispatch("submit")
  }

  apply() {
    setTimeout(() => this.element.closest("turbo-frame").reload(), 100)
  }

  rename(ev) {
    const name = ev.target.files[0].name.split(".").shift()
    this.nameTarget.value ||= name
  }
}
