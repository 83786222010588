import { Controller } from "@hotwired/stimulus"
import "bootstrap/dist/js/bootstrap.bundle"

export default class extends Controller {
  connect() {
    if (this.element.dataset.bsToggle == "tooltip") {
      new bootstrap.Tooltip(this.element, {
        container: this.element.closest(".modal, body")
      })
    }
  }
}
