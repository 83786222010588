import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["preview", "background", "name"]

  initialize() {
    this.src = null
  }

  preview(ev) {
    this.src = ev.target.labels[0]?.querySelector("img")?.src || ""
    this.previewTarget.src = this.src
  }

  submit() {
    this.dispatch("submit", { detail: { src: this.src } })
  }

  apply({ detail: { src } }) {
    this.backgroundTarget.style.backgroundImage = `url('${src}')`
  }

  rename(ev) {
    const name = ev.target.files[0].name.split(".").shift()
    this.nameTarget.value ||= name
  }
}
